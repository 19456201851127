<template>
  <AdminSingleCard showLogo>
    <template v-slot:title>
      ログアウト
    </template>

    <template v-slot:description>
      <p v-if="isConfirm" class="logout-description">
        Poshspaceからログアウトしても<br />
        よろしいでしょうか？
      </p>
      <p v-else class="logout-description">
        Poshspaceからログアウトしました。<br />
        続行するにはもう一度ログインしてください。
      </p>
    </template>

    <template v-slot:button>
      <div v-if="isConfirm" class="button-wrapper">
        <WideButton
          :label="'ログアウトする'"
          @click="$_logOut"
        />
        <WideButton
          :label="'キャンセル'"
          :backgroundColor="'#707070'"
          @click="$router.go(-1)"
        />
      </div>

      <WideButton
        v-else
        class="mt-4"
        :label="'ログインへ'"
        @click="$router.push('/admin/login')"
      />
    </template>
  </AdminSingleCard>
</template>

<script>
import WideButton from '@/components/atoms/common/AppWideButton'
import AdminSingleCard from '@/templates/admin/AppAdminSingleCard'
import { auth, signOut } from '@/firebase/config'
import { showInfoPopupAlert } from '@/helper/common'

export default {
  name: 'TheLogOut',
  components: {
    AdminSingleCard,
    WideButton
  },
  data: function () {
    return {
      email: '',
      password: '',
      errorMessage: '',
      showBottom: false,
      isConfirm: true,
      pngLogo: require('@/assets/image/logo/logo.png')
    }
  },
  methods: {
    $_logOut: async function () {
      this.$store.dispatch('loadingMask/showLoadingMask')
      try {
        await signOut(auth)
        this.isConfirm = false
      } catch (error) {
        showInfoPopupAlert(`ログアウトに失敗しました。（${error.message}`)
      } finally {
        this.$store.dispatch('loadingMask/hideLoadingMask')
      }
    }
  },
}
</script>

<style lang="scss">
.logout-description {
  margin: 16px auto 0 auto;
  text-align: center;
}
input {
  margin: 10px 0;
  padding: 10px;
  width: 100%;
  border: 1px solid gray;
  border-radius: 4px;
}
.login-title {
  margin: 16px 0;
}
.button-wrapper {
  padding-top: 16px;
  display: flex;
  flex-direction: column;
  row-gap: 12px;
}
</style>
